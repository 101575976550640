@import "../sprite";

.reviews {
	padding: 65px 0 60px;
	text-align: center;

	h2 {
		font-size: 36px;
		font-weight: 300;
		line-height: 16px;
		margin: 0;
	}

	&__list {
		margin: 45px 0 40px;
		padding: 0;
		list-style-type: none;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__list-item {
		width: 360px;
	}

	&__list-text {
		font-weight: 300;
		color: #343d48;
		padding: 30px;
		border: 1px dashed #6f8ac1;
		border-radius: 6px;
		position: relative;

		&::after {
			content: '';
			display: inline-block;
			@include sprite($arrow-review);
			position: absolute;
			bottom: -23px;
			left: 60px;
		}
	}

	&__list-person {
		margin-top: 30px;
		display: flex;
		align-items: center;

		img {
			border-radius: 100%;
			margin-right: 15px;
		}
	}
}

html.no-flexbox {
	.reviews__list li {
		display: inline-block;
		vertical-align: top;

		&:nth-child(2) {
			margin: 0 45px;
		}
	}
}