@import "sprite";

.price {
	display: inline-block;
	vertical-align: middle;
	color: #fff;
	font-size: 18px;
	line-height: 18px;
	border-radius: 18px;
	background-color: #54cc0c;
	padding: 8px 15px 9px;
}

.btn {
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	border: none;

	&.btn-default {
		background-image: linear-gradient(to top, #ebbd41 0%, #f8d906 100%);
		border-radius: 35px;
		box-shadow: 0 2px 0 #b19b0a;
		color: #343d48;
		font-size: 22px;
		padding: 20px 45px;

		&:active {
			box-shadow: none;
			margin: 2px 0 -2px;
		}
	}

	&.btn-vk {
		font-size: 0;
		@include sprite($vk);
		display: inline-block;
		margin-top: 10px;
	}
}

.table-default {
	width: 540px;
	margin: 0 auto 30px;
	background: #fff;
	box-sizing: border-box;
	text-align: left;

	thead {
		background: #b0def8;
		font-size: 20px;
		line-height: 16px;
		color: #343d48;

		td {
			padding: 0 30px;
			height: 50px;
		}
	}

	tbody {
		td {
			height: 40px;
			color: #343d48;

			&:first-child {
				padding-left: 30px;
			}

			&:last-child {
				padding-right: 30px;
				text-align: right;
			}
		}

		tr {
			&:nth-child(odd) {
				background: #f6f8f9;
			}

			&:nth-child(even) {
				background: #e8f4f7;
			}
		}
	}

	&__small {
		font-size: 12px;
		line-height: 10px;
		font-weight: 300;
	}
}