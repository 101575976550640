@import "../sprite";

.results {
	background: #f5fafd;
	padding: 75px 0 135px;

	&__wrapper {
		width: 980px;
		margin: 0 auto;
		box-sizing: border-box;
		border: 3px solid #b0def8;
		position: relative;
		text-align: center;
		padding: 40px 0 0;
	}

	h2 {
		font-size: 36px;
		line-height: 36px;
		font-weight: 300;
		color: #343d48;
		margin: 0;
		padding: 0 70px;
		background: #f5fafd;
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		white-space: nowrap;
		top: -22px;
	}

	&__text {
		font-weight: 300;
		color: #343d48;
		margin: 0;
	}

	&__slider {
		width: 580px;
		margin:0 auto;
		position: relative;
		bottom: -60px;

		&::before {
			content: '';
			display: block;
			width: 60px;
			height: 290px;
			background: linear-gradient(to right, #f5fafd 0%,#f5fafd 50%,#ddecf5 50%,#ddecf5 100%);
			position: absolute;
			top: 50%;
			left: -60px;
			transform: translateY(-50%);
		}

		&::after {
			content: '';
			display: block;
			width: 60px;
			height: 290px;
			background: linear-gradient(to right, #ddecf5 0%,#ddecf5 50%,#f5fafd 50%,#f5fafd 100%);
			position: absolute;
			top: 50%;
			right: -60px;
			transform: translateY(-50%);
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		top: 75%;
	}

	.swiper-button-prev {
		@include sprite($arrow-prev);
		background-size: auto;
		left: 80px;
	}

	.swiper-button-next {
		@include sprite($arrow-next);
		background-size: auto;
		right: 80px;
	}
}

html.no-flexbox {
	.results h2 {
		margin-left: -293px;
	}
}