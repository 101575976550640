/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$arrow-next-name: 'arrow-next';
$arrow-next-x: 0px;
$arrow-next-y: 56px;
$arrow-next-offset-x: 0px;
$arrow-next-offset-y: -56px;
$arrow-next-width: 56px;
$arrow-next-height: 56px;
$arrow-next-total-width: 140px;
$arrow-next-total-height: 570px;
$arrow-next-image: '../img/sprite.png';
$arrow-next: (0px, 56px, 0px, -56px, 56px, 56px, 140px, 570px, '../img/sprite.png', 'arrow-next', );
$arrow-prev-name: 'arrow-prev';
$arrow-prev-x: 0px;
$arrow-prev-y: 112px;
$arrow-prev-offset-x: 0px;
$arrow-prev-offset-y: -112px;
$arrow-prev-width: 56px;
$arrow-prev-height: 56px;
$arrow-prev-total-width: 140px;
$arrow-prev-total-height: 570px;
$arrow-prev-image: '../img/sprite.png';
$arrow-prev: (0px, 112px, 0px, -112px, 56px, 56px, 140px, 570px, '../img/sprite.png', 'arrow-prev', );
$arrow-review-name: 'arrow-review';
$arrow-review-x: 0px;
$arrow-review-y: 0px;
$arrow-review-offset-x: 0px;
$arrow-review-offset-y: 0px;
$arrow-review-width: 23px;
$arrow-review-height: 26px;
$arrow-review-total-width: 140px;
$arrow-review-total-height: 570px;
$arrow-review-image: '../img/sprite.png';
$arrow-review: (0px, 0px, 0px, 0px, 23px, 26px, 140px, 570px, '../img/sprite.png', 'arrow-review', );
$benefit-1-name: 'benefit-1';
$benefit-1-x: 0px;
$benefit-1-y: 168px;
$benefit-1-offset-x: 0px;
$benefit-1-offset-y: -168px;
$benefit-1-width: 140px;
$benefit-1-height: 134px;
$benefit-1-total-width: 140px;
$benefit-1-total-height: 570px;
$benefit-1-image: '../img/sprite.png';
$benefit-1: (0px, 168px, 0px, -168px, 140px, 134px, 140px, 570px, '../img/sprite.png', 'benefit-1', );
$benefit-2-name: 'benefit-2';
$benefit-2-x: 0px;
$benefit-2-y: 302px;
$benefit-2-offset-x: 0px;
$benefit-2-offset-y: -302px;
$benefit-2-width: 140px;
$benefit-2-height: 134px;
$benefit-2-total-width: 140px;
$benefit-2-total-height: 570px;
$benefit-2-image: '../img/sprite.png';
$benefit-2: (0px, 302px, 0px, -302px, 140px, 134px, 140px, 570px, '../img/sprite.png', 'benefit-2', );
$benefit-3-name: 'benefit-3';
$benefit-3-x: 0px;
$benefit-3-y: 436px;
$benefit-3-offset-x: 0px;
$benefit-3-offset-y: -436px;
$benefit-3-width: 140px;
$benefit-3-height: 134px;
$benefit-3-total-width: 140px;
$benefit-3-total-height: 570px;
$benefit-3-image: '../img/sprite.png';
$benefit-3: (0px, 436px, 0px, -436px, 140px, 134px, 140px, 570px, '../img/sprite.png', 'benefit-3', );
$vk-name: 'vk';
$vk-x: 0px;
$vk-y: 26px;
$vk-offset-x: 0px;
$vk-offset-y: -26px;
$vk-width: 106px;
$vk-height: 30px;
$vk-total-width: 140px;
$vk-total-height: 570px;
$vk-image: '../img/sprite.png';
$vk: (0px, 26px, 0px, -26px, 106px, 30px, 140px, 570px, '../img/sprite.png', 'vk', );
$spritesheet-width: 140px;
$spritesheet-height: 570px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($arrow-next, $arrow-prev, $arrow-review, $benefit-1, $benefit-2, $benefit-3, $vk, );
$spritesheet: (140px, 570px, '../img/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
