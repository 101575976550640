@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=PT+Sans&subset=cyrillic');
@import '../../bower_components/normalize-scss/normalize.scss';
@import "scaffolding";
@import "elements";
@import "swiper";
@import "blocks/header";
@import "blocks/top-form";
@import "blocks/services";
@import "blocks/benefits";
@import "blocks/prices";
@import "blocks/results";
@import "blocks/reviews";
@import "blocks/contacts";
@import "blocks/footer";