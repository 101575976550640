@import "../sprite";

.benefits {
	text-align: center;
	padding: 55px 0 90px;
	position: relative;

	&::after {
		display: block;
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		background: url('../img/service-line.png') 0 0 repeat-x;
		content: '';
		height: 13px;
	}

	h2 {
		font-size: 36px;
		font-weight: 300;
		line-height: 16px;
		margin: 0;
	}

	&__list {
		margin: 80px 0 55px;
		padding: 0;
		list-style-type: none;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	&__list-item {
		position: relative;
		width: 330px;

		&:nth-child(2) {
			margin: 0 40px;
		}

		&::before {
			content: '';
			display: inline-block;
		}

		&--guarantee::before {
			@include sprite($benefit-1);
		}

		&--saving::before {
			@include sprite($benefit-2);
		}

		&--overpayment::before {
			@include sprite($benefit-3);
		}
	}

	&__list-title {
		font-size: 16px;
		font-weight: 700;
		line-height: 16px;
		color: #6984b7;
	}

	&__list-text {
		font-size: 13px;
		font-weight: 300;
		line-height: 18px;
		color: #0e1927;
	}
}

html.no-flexbox {
	.benefits__list li {
		display: inline-block;
		vertical-align: top;
	}
}