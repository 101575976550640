.contacts {
	position: relative;

	.map {
		width: 100%;
		height: 590px;
	}

	&__wrapper {
		position: absolute;
		width: 760px;
		top: 60px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		text-align: center;
		transition: all .3s ease-out;

		&.opacity {
			opacity: 0;
			visibility: hidden;
		}
	}

	&__left {
		width: 50%;
		background: #fff;
		border-radius: 6px 0 0 6px;
		padding-top: 55px;
		color: #6884be;
	}

	&__form {
		width: 50%;
		background: #f5fafd;
		border-radius: 0 6px 6px 0;
		padding: 40px 0;
	}

	&__left-title {
		font-size: 30px;
		font-weight: 300;
		line-height: 36px;
		margin: 0;
	}

	&__left-phone {
		font-size: 24px;
		line-height: 36px;
		margin: 30px 0;
	}

	&__left-link {
		text-decoration: none;
		font-size: 16px;
		line-height: 16px;
		color: #6984b7;
		border-bottom: 1px dashed #6984b7;

		&:hover {
			border-bottom: 1px solid transparent;
		}
	}

	&__left-social {
		margin: 50px 0 0;
		font-size: 14px;
	}

	&__form-title {
		color: #6884be;
		font-size: 30px;
		font-weight: 300;
		line-height: 36px;
		margin: 0 0 20px;
	}

	&__form-input {
		width: 320px;
		height: 60px;
		background-color: #fff;
		border: 1px solid #e5f3fb;
		border-radius: 6px;
		box-sizing: border-box;
		padding: 0 20px;
		margin-bottom: 15px;
	}

	.btn-default {
		margin-top: 15px;

		&:active {
			margin: 17px 0 -2px;
		}
	}

	&__form-text {
		color: #6884be;
		margin-bottom: 0;
	}
}

html.no-flexbox {
	.contacts__wrapper {
		margin-left: -380px;
	}

	.contacts__left,
	.contacts__form {
		float: left;
	}

	.contacts__left {
		padding-bottom: 101px;
	}
}