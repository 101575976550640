@import '../sprite';

.services {
	background: #fff url('../img/service.jpg') 50% 0 no-repeat;
	text-align: center;
	padding: 60px 0 50px;

	h2 {
		color: #fff;
		font-size: 36px;
		font-weight: 300;
		line-height: 16px;
		margin: 0;
		background: url('../img/service-line-title.png') 50% 100% no-repeat;
		padding-bottom: 50px;
	}

	&__text {
		color: #fff;
		font-weight: 300;
		margin: 35px 0;
	}

	&__list {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin: 0 0 50px;
		padding: 0;
		list-style-type: none;
	}

	&__list-item {
		width: 270px;

		img {
			border-radius: 100%;
			border: 2px solid #fff;
		}

		p {
			color: #fff;
			font-size: 16px;
			font-weight: 700;
		}

		.price {
			padding: 6px 15px 7px;
		}

		&:nth-child(2) {
			margin: 0 95px;
		}
	}
}

html.no-flexbox {
	.services__list li {
		display: inline-block;
		vertical-align: top;
	}
}