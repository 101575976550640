.prices {
	background: url('../img/prices.jpg') 50% 0 no-repeat;
	padding: 70px 0 60px;
	text-align: center;

	h2 {
		font-size: 36px;
		font-weight: 300;
		line-height: 16px;
		margin: 0 0 50px;
		text-align: center;
		color: #fff;
	}

	.btn-default {
		margin-top: 10px;
	}
}