html,
body {
	font-family: 'Open Sans', 'Arial', sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 22px;
	min-width: 1180px;
	background: #fff;
}

.container {
	width: 1180px;
	margin: 0 auto;
}

.rub {
	font-family: 'PT Sans', sans-serif;
}