.header {
	padding: 16px 0 18px;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__wrapper-fix {
		background: rgba(255, 255, 255, .9);
		padding: 0;
		position: fixed;
		top: 0;
		z-index: 10;
		width: 100%;
		transition: all .3s;
		opacity: 0;
		visibility: hidden;

		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&.fixed {
			opacity: 1;
			visibility: visible;
		}
	}

	&__nav-list {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style-type: none;

		a {
			color: #6884be;
			text-decoration: none;
			padding: 15px;
			transition: color .2s ease-out;
			font-weight: 600;

			&:hover {
				color: darken(#6884be, 10%);
			}

			&:active {
				color: darken(#6884be, 20%);
			}
		}
	}

	&__contacts {
		text-align: right;
		color: #343d48;
		font-size: 16px;
		line-height: 16px;
	}

	&__phone {
		font-weight: 600;
		margin: 0 0 5px;
	}

	&__address {
		margin: 0;
		font-weight: 300;
	}
}

html.no-flexbox {
	.header__logo,
	.header__nav,
	.header__contacts {
		display: inline-block;
		vertical-align: middle;
	}

	.header__nav-list li {
		display: inline-block;
		vertical-align: middle;
	}

	.header__nav,
	.header__contacts {
		margin-left: 20px;
	}
}