.footer {
	background: #6984b7;
	padding: 30px 0;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__develop {
		font-size: 14px;
		line-height: 18px;
		color: #fff;

		a {
			color: #fff;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

html.no-flexbox {
	.footer__logo,
	.footer__develop {
		display: inline-block;
		vertical-align: middle;
	}

	.footer__develop {
		margin-left: 820px;
	}
}