.top-form {
	background: #fff url('../img/top-form.jpg') 50% 0 no-repeat;
	padding: 60px 0;
	position: relative;

	&::after {
		display: block;
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		background: url('../img/service-line.png') 0 0 repeat-x;
		content: '';
		height: 13px;
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	&__title {
		h1 {
			color: #1c2d51;
			font-size: 50px;
			font-weight: 700;
			text-transform: uppercase;
			margin: 0 0 20px;
			line-height: 50px;
		}
	}

	&__form {
		width: 380px;
		background-color: #f5fafd;
		border-radius: 8px;
		box-shadow: 0 3px 20px rgba(104, 132, 190, 0.28);
		text-align: center;
		padding: 40px 0 35px;
	}

	&__form-title {
		color: #6884be;
		font-size: 30px;
		font-weight: 300;
		line-height: 36px;
		margin: 0 0 20px;
	}

	&__form-input {
		width: 320px;
		height: 60px;
		background-color: #fff;
		border: 1px solid #e5f3fb;
		border-radius: 6px;
		box-sizing: border-box;
		padding: 0 20px;
		margin-bottom: 15px;
	}

	.btn-default {
		margin-top: 15px;

		&:active {
			margin: 17px 0 -2px;
		}
	}

	&__form-text {
		color: #6884be;
		margin-bottom: 0;
	}
}

html.no-flexbox {
	.top-form__title,
	.top-form__form {
		display: inline-block;
		vertical-align: bottom;
	}

	.top-form__form {
		margin-left: 165px;
	}
}